import React, { FC, lazy, Suspense, useEffect } from 'react';

import { WidgetModal } from '@ca-actual-projects/bca-widget';
import { ToastContainer } from 'react-toastify';
import { YMInitializer } from 'react-yandex-metrika';

import 'react-toastify/dist/ReactToastify.css';
import Glimmer from '../components/Glimmer/Glimmer';
import { useGeo } from '../hook/useGeo';
import { useHistoryWithUTM } from '../hook/useHistoryWithUTM';
import DocumentModel from '../models/DocumentModel/DocumentModel';
import { LandingStore } from '../themes';

import s from './App.module.scss';

const Home1 = lazy(() => import('./Home/Home1/Home1'));

export const App: FC = () => {
  const history = useHistoryWithUTM();
  const { openPdf } = DocumentModel();
  useEffect(() => {
    history.toHttps();
  }, []);
  useGeo();

  return (
    <>
      <YMInitializer
        accounts={[90896721]}
        options={{
          webvisor: true,
          clickmap: true,
          trackLinks: true,
          accurateTrackBounce: true,
          trackHash: true,
        }}
      />
      <div className={s.wrapper}>
        <main className={s.page} data-cy="main">
          <Suspense fallback={<Glimmer />}>
            <Home1
              banner={LandingStore.landing1.banner}
              loan={LandingStore.landing1.loan}
              footer={LandingStore.landing1.footer}
            />
          </Suspense>
        </main>
      </div>
      <WidgetModal
        pdfOpen={openPdf}
        short
        theme="sobank"
        backUrl="https://prod.onbank.online"
      />
      <ToastContainer />
    </>
  );
};
