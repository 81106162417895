import { useEffect, useState } from 'react';

// eslint-disable-next-line import/no-extraneous-dependencies
import { useLocation, useNavigate } from 'react-router';

export const useHistoryWithUTM = () => {
  const history = useNavigate();
  const location = useLocation();
  const [search, setSearch] = useState(location.search);
  useEffect(() => {
    setSearch(location.search);
  }, [location.search]);

  return {
    push: (pathname: string) => {
      history({ pathname, search });
    },
    checkProtocol(protocol: string) {
      return window.location.protocol === protocol;
    },
    toHttps() {
      if (this.checkProtocol('http:') && !window.location.origin.includes('localhost')) {
        window.location.href = window.location.href.replace('http:', 'https:');
      }
    },
    location,
    returnToBack: () => {},
  };
};
