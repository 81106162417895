import { createContext, FC, ReactNode } from 'react';

import { tabletWidth } from '../constants';
import { useMedia } from '../hook/useMedia';

export const MediaContext = createContext(false);

export const MediaProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const media = useMedia(tabletWidth);
  return <MediaContext.Provider value={media}>{children}</MediaContext.Provider>;
};
